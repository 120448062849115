export const authConfig = {
	//authority: 'https://login.microsoftonlisne.com/5ced40db-2fc3-4ef1-b1b3-48b4e94668f5',
	//clientID: '2f93b081-7abe-490c-ace5-eeeb111a42b0',
	//clientID: '934e7127-1aba-4835-9325-57d80d112f75',
	//clientID: '7c8bb28b-0a85-4c75-8112-194fb5b5946a',
	//clientID: 'd346b065-ed67-493e-b734-6d25afb6370c',
	//clientID: 'd346b065-ed67-493e-b734-6d25afb6370c',
	//clientID: 'fa33d1a4-eff7-4560-ac5a-03cd30ddc013',
	//clientID: 'fa33d1a4-eff7-4560-ac5a-03cd30ddc013',
	//clientID: 'ae5ceb51-4b9f-4a8e-91e2-1e3377d75d62',
  clientID: '4898a950-0872-41a1-b68a-1f15b9d71747',
  authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
	/* 	endpoints:{
			'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
	'https://graph.microsoft.com': 'https://graph.microsoft.com'
		}, */
	//clientID: '4d53b2c4-3bd4-4645-a3ef-da39ab474c2a',
	//redirectUri: 'https://apsis-dev-ui.azurewebsites.net',
	//redirectUri: 'https://apsiswebdev.azurewebsites.net/',
	//redirectUri: 'https://apsiswebdev-staging.azurewebsites.net/',
	//redirectUri: 'https://apsisweb.azurewebsites.net/',
	redirectUri: 'https://apsis.ltimindtree.com/',
	//redirectUri: 'https://apsiswebdev-staging.mindtree.com/',
	// redirectUri : 'http://localhost:4200',
	scopes: ['People.Read.All'],
	// People.Read.All - Requires an Administrator to grant this permission
	protectedResourceMap: [['https://graph.microsoft.com', ['00000002-0000-0000-c000-000000000000']], ['https://graph.windows.net', ['People.Read.All']]]

};
