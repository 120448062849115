import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { RESULT_CODES } from 'src/app/models/Constants/RESULT_CODES';
import { requestDetails } from 'src/app/models/requestDetails';
import { ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppService } from "src/app/app.service";
import { ROLES_CONST } from "src/app/models/Constants/ROLES_CONST";
import { AppToastService } from "src/app/ui-component/custom-ui-components/toaster/app-toast.service";
import { VerifyRoleService } from "src/app/verify-role.service";
import { EvaluationResult } from 'src/app/models/EvaluationResult';
import { UserService } from 'src/app/global-component/services/api-mapping-services/user.service';
import { RequestService } from 'src/app/global-component/services/api-mapping-services/request.service';
import { ActivatedRoute } from '@angular/router';
import { responseStatus } from 'src/app/models/responseStatus';
import { ConfirmDialogComponent } from 'src/app/ui-component/custom-ui-components/modals/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from 'src/app/ui-component/custom-ui-components/modals/confirm-dialog/confirm-dialog.service';
import { UploadModalService } from 'src/app/ui-component/custom-ui-components/modals/upload-dialog/upload-modal.service';
//import { AlertService } from 'src/app/ui-component/custom-ui-components/modals/alert/alert.service';
import { Evaluation } from 'src/app/models/Evaluation';
import { RequestdetailsComponent } from '../requestdetails.component';
@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  @Output() updateStaus = new EventEmitter();
  public resultCodes = RESULT_CODES;
  public scoreCardFile;
  public ScorecardFilePath;
  commentsValues: string;
  public errorFilePath;
  public errorFile;
  public notClearedScorecard;
  public model: responseStatus;
  public clearScoreCard;
  public verify_role_evaluator: boolean;
  public verify_role_learner: boolean;
  public roles: { Evaluator: string; Learner: string; OPM: string; };
  public requestId: number;
  public learnerMid: string;
  public evaluationRequestID: number;
  public evaluationRequestType: string;
  showScore: boolean = true;
  showScoreCard: boolean = true;
  public resultS: string = RESULT_CODES.notclear;
  public ResultDetailsForm: FormGroup;
  fd: FormData = new FormData();
  scoreErrorText: string = '';
  fileErrorText: string = '*Please upload the file';
  isSubmitted: boolean = false;
  isCompletedRequest: boolean = false;
  fileSize: number;

  uploadedFile: any;
  comments: string;
  textAreaLength: number = 0;
  scoreSecured: any = "";
  isCommentsEmpty: boolean
  isScoreError: boolean
  isFileEmpty: boolean;
  fileList: FileList;
  radioButtonValue: any = this.resultCodes.notclear;


  @Input() public requestDetailsModel: any;
  @Output() public resultDetailsEvent = new EventEmitter();
  @ViewChild("file", { static: true }) file;
  //@ViewChild('submitButton', { static: true }) submitButton: ElementRef;
  message: string;
  toasterService: any;
  alertsService: any;
  commentsValue: string = '';
  submitInProgress: boolean;
  _data: responseStatus;
  submitButtonText: string;

  constructor(public appService: AppService, private formBuilder: FormBuilder, private toastService: AppToastService,
    // ,private alertService:AlertService,
    private confirmDialogService: ConfirmDialogService,
    private _popup: MatDialog,

    private uploadModalService: UploadModalService,
    private verify_role_service: VerifyRoleService
    , private requestService: RequestService
    , private route: ActivatedRoute) {

  }

  public ngOnInit() {
    this.submitButtonText = 'Submit';
    this.ResultDetailsForm = new FormGroup({

      comments: new FormControl('', Validators.required),
      score: new FormControl('', [Validators.pattern('^([0-9]{1,5}|[0-9]{0,5}[.][0-9]{1,2})$')]),
      scoreCard: new FormControl('', [Validators.pattern('^.*\.(zip)$')])
    });

    this.evaluationRequestID = this.route.snapshot.params['requestId'];
    this.evaluationRequestType = this.route.snapshot.params['evaluationRequestType'];
    this.requestService.getRequestDetails(this.evaluationRequestType, this.evaluationRequestID).subscribe(data => {
      if (data) {
        this.requestDetailsModel = data as requestDetails;
        this.comments = this.requestDetailsModel.comments;
        this.scoreSecured = this.requestDetailsModel.score > 0 ? this.requestDetailsModel.score : "";
        if (!this.requestDetailsModel.subStatus.includes('Result awaited')) {
          this.submitButtonText = 'Submitted';
          this.isCompletedRequest = true;
        }
        else {
          this.scoreSecured = "";
          this.comments = "";
          this.radioButtonValue = RESULT_CODES.notclear;
        }

        if (this.isCompletedRequest == true) {
          this.submitButtonText = 'Submitted';
          if (this.requestDetailsModel.result == 'Clear')
            this.radioButtonValue = RESULT_CODES.cleared;
          else if (this.requestDetailsModel.result == 'Not-Cleared')
            this.radioButtonValue = RESULT_CODES.notclear;
          else if (this.requestDetailsModel.result == 'Error')
            this.radioButtonValue = RESULT_CODES.error;
        }
      }
    });
  }

  onTextAreaChange(event: any) {
    this.textAreaLength = event.target.value.length;
    console.log('Event', event.target.value);
    if (this.textAreaLength > 0)
      this.isCommentsEmpty = false;
  }

  onScoreChange() {
    if (this.scoreSecured.trim().length === 0) {
      this.isScoreError = true;
      this.scoreErrorText = "*Score is mandatory";
    }
    if (isNaN(this.scoreSecured)) {
      this.isScoreError = true;
      this.scoreErrorText = "*Enter a valid score";
    }
    if (this.scoreSecured.includes('.') || this.scoreSecured.includes('-')) {
      this.isScoreError = true;
      this.scoreErrorText = "*Enter a valid score";
    }
    else if (this.scoreSecured >= 0 && this.scoreSecured.length > 0 && !isNaN(this.scoreSecured)) {
      this.isScoreError = false;
    }
  }

  get f() { return this.ResultDetailsForm.controls; }

  public onFileChange(event) {
    console.log(event);

    console.log(this.resultS);
    let file = null;

    this.fileList = event.target.files;

    if (this.fileList.length > 0) {
      file = event.target.files[0];
      this.fd.append('File', file, file.name);
      this.fileSize = parseFloat((file.size / Math.pow(1024, 2)).toFixed(2));
      console.log('filesize', this.fileSize);
      if (this.fileSize <= 5) {
        this.isFileEmpty = false;
        this.scoreCardFile = file;
        this.ScorecardFilePath = file.name;
      }
      else {
        this.isFileEmpty = true;
        this.scoreCardFile = '';
        this.fileErrorText = '*Please upload file of less than 5MB'
      }
    }
    else {
      this.isFileEmpty = true;
      this.scoreCardFile = '';
    }
    if (this.resultS === 'Not-Cleared') {
      this.clearScoreCard = file.name;
      console.log(this.clearScoreCard);
    }
    else if (this.resultS === 'Clear') {
      this.clearScoreCard = file.name;
      console.log(this.clearScoreCard);

    }
    else if (this.resultS === 'Error') {
      this.notClearedScorecard = file.name;
      console.log(this.notClearedScorecard);
    }
  }


  public submitDetails() {
    this.isScoreError = false;
    var isScoreValid = this.scoreSecured - Math.floor(this.scoreSecured) !== 0;
    console.log('score', this.scoreSecured.length, Number.isInteger(this.scoreSecured));
    console.log('filelength', this.fileList);
    if (this.fileList == null)
      this.isFileEmpty = true;
    if (this.textAreaLength > 0)
      this.isCommentsEmpty = false;
    else if (this.textAreaLength == 0)
      this.isCommentsEmpty = true;
    if (this.scoreSecured.trim().length === 0) {
      this.isScoreError = true;
      this.scoreErrorText = "*Score is mandatory";
    }
    if (isNaN(this.scoreSecured)) {
      this.isScoreError = true;
      this.scoreErrorText = "*Enter a valid score";
    }
    if (this.scoreSecured.includes('.') || this.scoreSecured.includes('-')) {
      this.isScoreError = true;
      this.scoreErrorText = "*Enter a valid score";
    }
    if (this.scoreSecured >= 0 && this.scoreSecured.length > 0 && !this.isScoreError) {
      console.log('hitthis');
      if (this.resultS == 'Clear') {
        if (this.scoreSecured >= this.requestDetailsModel.passingScore
          && this.scoreSecured <= this.requestDetailsModel.maxScore)
          this.isScoreError = false;
        else if (this.scoreSecured < this.requestDetailsModel.passingScore) {
          this.isScoreError = true;
          this.scoreErrorText = "*Score cannot be less than the passing score if the result is clear.";
        }
        else if (this.scoreSecured > this.requestDetailsModel.maxScore) {
          this.isScoreError = true;
          this.scoreErrorText = "*Score cannot be more than the maximum score.";
        }
      }
      else if (this.resultS == 'Not-Cleared') {
        if (this.scoreSecured >= this.requestDetailsModel.passingScore
          && this.scoreSecured <= this.requestDetailsModel.maxScore) {
          this.isScoreError = true;
          this.scoreErrorText = "*Score cannot be more the passing score if the result is Not-Clear";
        }
        else if (this.scoreSecured < this.requestDetailsModel.passingScore)
          this.isScoreError = false;
        else if (this.scoreSecured > this.requestDetailsModel.maxScore) {
          this.isScoreError = true;
          this.scoreErrorText = "*Score cannot be more than the maximum score.";
        }
      }
    }

    if (this.fileList != null) {
      if (this.fileList.length > 0)
        this.isFileEmpty = false;
    }
    else
      this.isFileEmpty = true;

    if (!this.isCommentsEmpty && (!this.isScoreError || this.resultS == "Error") && this.fileSize <= 5 && !this.isFileEmpty) {
      this.submitInProgress = true;
      var result = new EvaluationResult();
      result.evaluationRequestId = this.evaluationRequestID;
      console.log(result.evaluationRequestId);
      result.requestId = this.requestDetailsModel.requestId;
      console.log(result.requestId);
      result.evaluationType = this.evaluationRequestType;
      result.result = this.resultS;
      result.learnerId = this.requestDetailsModel.learnerId;
      result.learnername = this.requestDetailsModel.learnerName;
      result.learneremail = this.requestDetailsModel.learnerEmail;
      result.courseid = this.requestDetailsModel.courseId;
      result.coursename = this.requestDetailsModel.courseName
      result.maxScore = this.requestDetailsModel.maxScore;

      result.courseid = this.requestDetailsModel.courseId;

      if (this.resultS != 'Error') {
        this.errorFilePath = null;
      }
      else {
        this.ScorecardFilePath = null;
      }
      //this.scoreCardFile = this.clearScoreCard ? this.clearScoreCard : this.notClearedScorecard;
      console.log(this.scoreCardFile);

      result.comments = this.comments;

      result.score = this.scoreSecured != "" ? this.scoreSecured : 0;
      //result.result=this.resultS;

      let fi = this.scoreCardFile;
      console.log('FileToUpload', this.fd);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'confirmDialogContainer';
      const dialogRef = this._popup.open(ConfirmDialogComponent, dialogConfig);
      //  dialogRef.afterClosed().subscribe((_data: any)=>{
      dialogRef.afterClosed().subscribe(canSubmit => {
        if (canSubmit) {
          //if(fi.files)
          {
            let filetoUpload = fi;
            this.requestService.uploadFile(this.evaluationRequestType, this.evaluationRequestID, result.learnerId, this.resultS, this.fd).subscribe(
              response => {
                if (this.resultS != 'Error') {
                  result.scorecard = response.toString();
                  console.log('Uploading abcde', result.scorecard);
                }

                if (this.resultS === 'Error') {
                  result.errorfile = response.toString();
                  console.log('uploading errorfile', result.errorfile)
                }

                if (response) {
                  this.message = 'File uploaded';
                  console.log('cansubmit', canSubmit);
                  this.message = '';
                  this.requestService.submitEvaluationResult(result).subscribe(
                    (data) => {
                      this.model = this._data as responseStatus;
                      this.isSubmitted = true;
                      this.isCompletedRequest = true;
                      if (data) {
                        this.submitButtonText = "Submitted";
                      }
                      else {
                        this.submitButtonText = "Submitted";
                      }
                    });
                }

              });


          }
        }
      });


    }
    else if (this.fileSize > 5) {
      this.isFileEmpty = true;
      this.scoreCardFile = '';
      this.fileErrorText = '*Please upload file of less than 5MB'
    }
  }

  public getStatus(value) {
    console.log(this.resultS, value);
    this.showScore = true;
    this.showScoreCard = true;
    this.resultS = value;
    this.isCommentsEmpty = false;
    this.isFileEmpty = false;
    this.isScoreError = false;
    this.clearScoreCard = '';
    this.notClearedScorecard = '';
    this.fileList = null;
    if (value == this.resultCodes.error) {
      this.showScore = false;
      this.showScoreCard = false;
    }
    this.updateStaus.emit(value);
    this.ResultDetailsForm.value.scoreCard = null;
    this.scoreCardFile = null;
  }
}


