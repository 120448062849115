// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl:"http://localhost:49239/api"
 // apiUrl:"https://apsisapidev.azurewebsites.net/api"
  //apiUrl: "https://apsisapidev-staging.azurewebsites.net/api"
  // apiUrl: "https://apsisapidev-staging.mindtree.com/api"
  apiUrl: "https://apsisapi.ltimindtree.com/api"

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
