import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NotFoundComponent } from "src/app/components/not-found/not-found.component";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { CustomUiComponentsModule } from "src/app/ui-component/custom-ui-components/custom-ui-components.module";
import { MatModuleModule } from "src/app/ui-component/mat-module/mat-module.module";
import { routing } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTPListener } from "src/app/global-component/services/http-interceptor/http-interceptor.service";
import { HomeComponent } from "./components/home/home.component";
import { DatePipe } from "@angular/common";
import { WINDOW_PROVIDERS } from "src/app/global-component/shared/services/window.service";
import { LoginComponent } from "./login/login.component";
//import { MatSortModule, MatSort, MatTableDataSource, MatTableModule } from '@angular/material';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import {AlertService}from 'src/app/ui-component/custom-ui-components/modals/alert/alert.service';

import {
  MatTableDataSource,
  MatTableModule
} from "@angular/material/table";
import {
  MatSortModule,
  MatSort
} from "@angular/material/sort";
import { MatIconModule } from "@angular/material/icon";
import { MyRequestListComponent } from "./modules/request/my-request-list/my-request-list.component";
import { ArrayPropertyFilterPipe } from "./array-property-filter.pipe";
import { RequestModule } from "./modules/request/request.module";
import { environment } from "../environments/environment";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, ProtectedResourceScopes } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { authConfig } from './global-component/authentication/Config';
@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ServiceWorkerModule.register("./../../ngsw-worker.js", {
      enabled: false
    }),
    MatDialogModule,
    MatButtonModule,
    HttpClientModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatModuleModule,
    CustomUiComponentsModule,
    MatSortModule,
    // AlertService,
    MatTableModule,
    MatIconModule,
    RequestModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: false }),
    MatPaginatorModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: authConfig.clientID,
        authority: authConfig.authority,
        redirectUri: authConfig.redirectUri,
      },
      cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
       // scopes: ['user.read', 'https://mindtreeonline.onmicrosoft.com/393638e6-618d-48c1-947d-138665e139a6/user_impersonation']
       // scopes: ['user.read', 'https://apsisapidev-staging.azurewebsites.net/user_impersonation']
        scopes: ['user.read', 'https://apsisprodapi.ltimindtree.com/user_impersonation']
      },
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>([
        ["https://graph.microsoft.com/v1.0/me", ['user.read', 'profile', 'User.Read.All']],
        //["https://apsisapidev.azurewebsites.net/", ['https://apsisapidev-staging.azurewebsites.net/user_impersonation']],
        // ["https://apsisapidev-staging.mindtree.com/", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        // ["https://apsisapidev-staging.mindtree.com/", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],        
        // ["http://localhost:49239/", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        // ["https://apsisapidev.azurewebsites.net/", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        ["https://apsisapi.ltimindtree.com/", ['https://apsisprodapi.ltimindtree.com/user_impersonation']]
      ])
    })
  ],
  exports: [MatTableModule, MatSortModule],
  declarations: [
    AppComponent,
    NotFoundComponent,
    HomeComponent,
    LoginComponent,
    ArrayPropertyFilterPipe,
    MyRequestListComponent
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    HTTPListener,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    WINDOW_PROVIDERS,
    DatePipe,
    MsalGuard
  ]
})
export class AppModule { }
