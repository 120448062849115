<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css" />
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.0/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"></script>

<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"></script>

<style>

</style>
<div class="main-content">
  <div class="card-header main-header">
    <div>
      <div>
        <div>
          <div class="floatLeft">
            <!-- <img [src]=requestDetails?.imageUrl alt="avatar" class="avatar"/>  -->
            <img src="assets/logo.png" alt="avatar" class="avatar" />
          </div>
          <div class="desc-style">
            <div class="learnerName">{{requestDetails?.learnerName}}</div>
            <div>{{requestDetails?.learnerId}}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="right-section-title">
          <div>
            <div class="courseNameHeading"><b> {{requestDetails?.courseName}}</b></div>
            <table>
              <tr>
                <td>Request Id</td>
                <td class="colonSpace">:</td>
                <td>{{requestDetails?.requestId}}</td>
              </tr>
              <tr>
                <td>Expertise</td>
                <td class="colonSpace">:</td>
                <td>{{requestDetails?.expertise}} </td>
              </tr>
              <tr>
                <td>Skill</td>
                <td class="colonSpace">:</td>
                <td>{{requestDetails?.skill}}</td>
              </tr>
              <tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body body-left-child">
    <div class="row">
      <div class="col-md-3">


        <div class="form-group col-lg-12"><button class="btn btn-primary btn-prm-override buttons" type="button"
            onclick="this.blur();" (click)="downloadScoreCard(requestDetails?.scoreCard)"
            [disabled]="requestDetails?.scoreCard === null"> <i
              class="spriteIcons  sprite_download floatRight downloadAllIcon"></i>Score Card
          </button> </div>

        <div class="form-group col-lg-12"> <button class="btn btn-primary btn-prm-override buttons" type="button"
            [disabled]="downloadEvaluationSummarySheetDisable(requestDetails?.evalutaionTemplate)"
            (click)="downloadEvaluationSummarySheet(requestDetails?.evalutaionTemplate)" onclick="this.blur();">
            <i class="spriteIcons  sprite_download floatRight downloadAllIcon"></i>
            Evaluation Sheet

          </button></div>

        <div class="form-group  col-lg-12 "><button type="button" class="buttons btn btn-primary btn-prm-override"
            [disabled]="downloadAssignmentDisable(requestDetails?.solutionFile)"
            (click)="downloadAssignment(requestDetails?.solutionFile)" onclick="this.blur();">
            <i class="spriteIcons sprite_download floatRight downloadAllIcon"></i> Solution
            <!-- <span class="glyphicon glyphicon-download badgestyle"></span> -->
          </button></div>

        <div class="form-group col-lg-12"><button class="buttons btn btn-primary btn-prm-override" type="button"
            [disabled]="downloadProblemStatementDisable(requestDetails?.problemStatement)"
            (click)="downloadProblemStatement(requestDetails?.problemStatement)" onclick="this.blur();">Problem
            Statement
            <i class="spriteIcons sprite_download floatRight downloadAllIcon"></i>
          </button></div>
      </div>

      <div class="col-md-9" style="height: auto;">
        <ngb-tabset class="tabset1">
          <ngb-tab title="Results">
            <ng-template ngbTabContent>
              <app-result *ngIf="!loading" (updateStaus)="getUpdateStatus($event)"
                [requestDetailsModel]="requestDetails"></app-result>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Log">
            <ng-template ngbTabContent>
              <app-log [logInput]="logInput"></app-log>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</div>